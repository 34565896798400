import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from "../components/seo";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import _ from 'lodash';

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const { frontmatter, html, excerpt } = post;
  
  const postImage = getImage(frontmatter.image?.childImageSharp?.gatsbyImageData);

  const seoImageUrl = frontmatter.image ? frontmatter.image.publicURL : null;

  const seoDescription = frontmatter.description || excerpt;

  const recentPosts = data.recentPosts.edges;

  return (
    <>
      <Seo title={frontmatter.title} description={seoDescription} image={seoImageUrl} />
      <Navigation />
      <div className="min-h-screen py-12">
        <div className="max-w-3xl mx-auto px-4">
          <article className="space-y-8 bg-white overflow-hidden">
            <header className="text-center space-y-4 p-6 text-gray-800">
              <h1 className="text-5xl font-extrabold leading-tight">{frontmatter.title}</h1>
              <p className="text-xl italic">Published on {frontmatter.date}</p>
              {frontmatter.tags && (
                <div className="flex flex-wrap justify-center gap-2 mt-4">
                  {frontmatter.tags.map((tag) => (
                    <Link
                      key={tag}
                      to={`/tags/${_.kebabCase(tag.toLowerCase())}/`}
                      className="bg-blue-200 text-blue-800 px-3 py-1 rounded-full text-sm font-semibold hover:bg-blue-300 transition duration-300"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              )}
            </header>
            {postImage && (
              <GatsbyImage image={postImage} alt={`Cover Image for ${frontmatter.title}`} className="w-full" />
            )}
            <div className="px-6 py-8">
              <section className="prose prose-lg max-w-full text-gray-800 leading-relaxed markdown" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            {recentPosts && recentPosts.length > 0 && (
              <div className="px-6 py-8 bg-blue-50">
                <h2 className="text-2xl font-bold mb-4">Recent Posts</h2>
                <ul className="space-y-4">
                  {recentPosts.map(({ node }) => (
                    <li key={node.fields.slug}>
                      <Link to={node.fields.slug} className="text-blue-600 hover:text-blue-800 transition duration-300">
                        {node.frontmatter.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </article>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        tags
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 900
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF, PNG, JPG]
            )
          }
          publicURL
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
